/* Define Sofia Pro font */
@font-face {
  font-family: "Sofia Pro";
  src: url("./fonts/sofiapro.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("./fonts/sofiapro-light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("./fonts/sofiapro-semibold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("./fonts/sofiapro-bold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: "Sofia Pro", sans-serif;
  letter-spacing: 0.3px;
  font-size: 16px;
}
.custom-form-item .ant-form-item-label {
  padding: 0; /* Custom padding for this specific form item label */
}

/* Gallery.css */
.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.gallery-item img {
  width: 100%;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: azure; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
